import { Stack, Typography, Card, CardHeader, colors } from "@samacare/design";
import { alpha } from "@samacare/design/core/styles";
import {
  EnhancedServicesIcon,
  EnahanceServiceIndicatorSingular,
} from "../EnhancedServicesIndicator";

export const PaBvTriggerCard = ({
  children,
  drugName,
  displayHeader = true,
  styleOverrides,
}: {
  children?: React.ReactNode;
  drugName: string;
  displayHeader?: boolean;
  styleOverrides?: React.CSSProperties;
}) => (
  <Card sx={{ width: "100%", maxWidth: "600px", my: "4px", ...styleOverrides }}>
    {displayHeader && (
      <CardHeader
        sx={{
          backgroundColor: alpha(colors.C500, 0.08),
        }}
        title={
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" mr={1}>
              Drug Verification
            </Typography>
            <EnahanceServiceIndicatorSingular
              styleOverride={{ textVariant: "caption" }}
            />
            <EnhancedServicesIcon sx={{ fontSize: 18 }} />
          </Stack>
        }
        subheader={`SamaCare investigated payer coverage, requirements and financials for ${drugName}`}
        subheaderTypographyProps={{ variant: "body2" }}
      />
    )}
    {children}
  </Card>
);
